<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "SetupPayment",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "4.1 How to set up payment",
          description: [
            {
              text: "1. To set up a payment for the first time, go to the “Account” at the bottom tab. Tap the “+Add Card” button under the Payment method on the dashboard.",
              imgType: true,
              img: "400.Account – empty – 2.png",
            },
            {
              text: "2. You will see “Add credit/debit card”. Enter your Card number; Valid till (MM/YY format) ; CVV. Tap on the “Submit” button at the bottom, and you are done! Kloser currently accepts: All credit /debit cards from Visa, MasterCard, and AMEX",
              imgType: true,
              img: "404.Add card – 3.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
